import useRedux from '../../../hooks/use-redux'
import { useEffect, useState } from 'react'
import {
  GreenCircleIncomingCallIcon,
  RedCircleHangUpCallIcon,
  CircleMicrophoneSlashIcon,
  CircleDialPadIcon,
  CirclePauseIcon,
  RedCircleXIcon,
  AvatarPlaceHolder,
} from '../../command-center-icon/icons/icons'
import {
  OutgoingCallHangupIcon,
  OutgoingCallPauseCallIcon,
  OutgoingCallMutedIcon,
  AlarmClockIcon,
  CallMessageIcon,
  CircleMessageIcon,
} from '@icons'
import { useAuth0 } from '@auth0/auth0-react'
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/pro-solid-svg-icons'
import { Avatar } from '@thryvlabs/maverick'
import Keypad from '../../calls/keypad/keypad'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import formatContactData from '../../inbox/hooks/formatContactData'
import { getLegsApi, transferToVoiceMail } from '../../../vonage/vonageAPI'
import {
  setCallStatus,
  setCallMuted,
  setCallOnHold,
  clearCallEvent,
  setCallDuration,
  setClientNumber,
  setClientUid,
  setCallDirection,
  setOnIncomingCall,
  setUserUid,
  setAnswerIsClicked,
} from '../../calls/slices/callSlice'
import { setMinutesRemaining } from '../../../redux-toolkit'
import {
  roundToTwoDecimalPlaces,
  secondsToMinutesConverter,
} from '../../calls/add-minutes/SecondsToMinutesConverter'
import avatarColorPicker from '../../../utils/avatarColorPicker'
import useContactName from '../../calls/hooks/useContactName'
const IncomingCallsWidget = ({ isMobile }) => {
  const [dispatch, useSelector] = useRedux()
  const {
    callStatus,
    dtmf,
    callOnHold,
    callMuted,
    callDuration: { secs, mins },
    clientNumber,
    clientUid,
    userUid,
    audio,
    callDirection,
    onIncomingCall,
    onOutgoingCall,
    answerIsClicked,
    vonageClient,
  } = useSelector((state) => state.calls)
  const { phoneNumber } = useSelector((state) => state.contacts.contacts)
  const { minutesRemaining } = useSelector((state) => state.meetings)
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const [onCall, setOnCall] = useState(false) //TODO: Add this to redux (a piece of state similar to this already exists in contacts-list-view.jsx)
  const [phoneString, setPhoneString] = useState('')
  const [expandedView, setExpandedView] = useState(true)
  const [dialPadOpen, setDialPadOpen] = useState(false)
  const [minutes, setMinutes] = useState(mins)
  const [seconds, setSeconds] = useState(secs)
  const [isTransferring, setIsTransferring] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [rejectIsClicked, setRejectIsClicked] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const contactName = useContactName()
  const {
    desktop: desktopNotifications,
    mobile: mobileNotifications,
    doNotDisturb,
  } = useSelector((state) => state.userPreferences.notificationPreferences)

  const incomingWeb = desktopNotifications?.find(
    (item) => item.label === 'Calls',
  )?.value

  const incomingMobile = mobileNotifications?.find(
    (item) => item.label === 'Calls',
  )?.value

  const handleDialPadOnChange = (e) => {
    if (e.type === 'delete') {
      let deleteFromPhoneString = phoneString.slice(0, phoneString.length - 1)
      setPhoneString(deleteFromPhoneString)
    } else if (phoneString.length > 17) {
      return
    } else {
      let updateToString = phoneString + e.key
      if (phoneString.length === 3) {
        updateToString = `(${phoneString}) - ${e.key}`
      }
      if (phoneString.length === 11) {
        updateToString = `${phoneString} - ${e.key}`
      }
      setPhoneString(updateToString)
    }
  }

  const resetCallEvent = () => {
    setDisabledButton(false)
    setIsTransferring(false)
    audio.pause()
    setTimeout(() => {
      setMinutes(0)
      setSeconds(0)
      dispatch(clearCallEvent())
      setOnCall(false)
      setRejectIsClicked(false)
    }, 2000)
  }

  const getClientUid = async () => {
    const convsPage = await vonageClient.app.getConversations()
    const convId = convsPage.conversations[0]?.id
    const legs = await getLegsApi(convId, vonageClient.jwt_no_sub)
    if (legs.length) {
      const { id: clientId } = legs.find(({ id }) => id !== userUid)
      dispatch(setClientUid(clientId))
    } else {
      setDisabledButton(true)
    }
  }

  // timer
  useEffect(() => {
    let timer
    if (callStatus === 'answered') {
      timer = setTimeout(() => {
        if (seconds + 1 === 60) {
          setSeconds(0)
          setMinutes(minutes + 1)
        } else {
          setSeconds(seconds + 1)
        }
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
      if (callStatus !== 'completed') {
        dispatch(setCallDuration({ minutes, seconds }))
      }
    }
  }, [seconds, callStatus])

  useEffect(() => {
    if (vonageClient.app) {
      const symbol = vonageClient.app.on('callInvite', (callId, from) => {
        // check if this incoming call is outside of the user's country
        if (countryIso2 && from) {
          // CA/US users will reject calls from AU&NZ
          if (['US', 'CA'].includes(countryIso2) && from[0] !== '1') {
            vonageClient.app.hangup(callId)
          }
          // AU reject calls from outside AU
          else if (countryIso2 === 'AU' && from.slice(0, 2) !== '61') {
            vonageClient.app.hangup(callId)
          }
          // NZ reject calls from outside NZ
          else if (countryIso2 === 'NZ' && from.slice(0, 2) !== '64') {
            vonageClient.app.hangup(callId)
            // incoming calls that have the same country code
          } else {
            dispatch(setClientNumber(from))
            dispatch(setOnIncomingCall(true))
            dispatch(setCallDirection('Incoming'))
            dispatch(setUserUid(callId))
            dispatch(setCallStatus('ringing'))
            dispatch(setAnswerIsClicked(false))
          }
        }
      })
      const symbol2 = vonageClient.app.on(
        'legStatusUpdate',
        (_callId, legId, statusObj) => {
          // do nothing update callstatus if other user is making outgoing call so UI won't be affected by this
          if (onIncomingCall || onOutgoingCall) {
            const status = statusObj.y7_1.toLocaleLowerCase()
            if (status === 'answered') {
              dispatch(setCallStatus(status))
            }
            if (status === 'completed' && (answerIsClicked || onOutgoingCall)) {
              dispatch(setCallStatus('completed'))
            }
          }
        },
      )
      const symbol3 = vonageClient.app.on('callInviteCancel', (callId, reason) => {
        if (
          [
            'AnsweredElsewhere',
            'RejectedElsewhere',
            'RemoteCancel',
            'RemoteTimeout',
          ].includes(reason.name)
        ) {
          if (answerIsClicked || onOutgoingCall) dispatch(setCallStatus('completed'))
          if (reason.name === 'RemoteCancel' && (!rejectIsClicked || onOutgoingCall))
            dispatch(setCallStatus('completed'))
        }
      })

      return () => {
        if (vonageClient.app) {
          vonageClient.app.off('callInvite', symbol)
          vonageClient.app.off('legStatusUpdate', symbol2)
          vonageClient.app.off('callInviteCancel', symbol3)
        }
      }
    }
  }, [answerIsClicked, vonageClient.app])

  useEffect(() => {
    if (
      ['cancelled', 'busy', 'timeout', 'failed', 'rejected'].includes(callStatus)
    ) {
      resetCallEvent()
    }
    if (callStatus === 'ringing' || callStatus === 'started') {
      getClientUid()
      if (
        typeof minutesRemaining === 'number' &&
        minutesRemaining > 0 &&
        !(
          doNotDisturb ||
          (!isMobile && !incomingWeb) ||
          (isMobile && !incomingMobile)
        )
      ) {
        audio.loop = true
        audio.play()
      }
    }

    if (callStatus === 'answered' && (answerIsClicked !== null || onOutgoingCall)) {
      if (answerIsClicked || onOutgoingCall) {
        audio.pause()
        setOnCall(true)
      } else {
        resetCallEvent(false)
      }
    }
    if (callStatus === 'completed') {
      dispatch(
        setMinutesRemaining(
          roundToTwoDecimalPlaces(
            minutesRemaining - (mins + secondsToMinutesConverter(secs)),
          ),
        ),
      )
      resetCallEvent()
    }
  }, [callStatus, answerIsClicked])

  /* Vonage Call Functions */
  // handle IVR(Interactive Voice Response) call
  useEffect(() => {
    if (callStatus === 'answered' && dtmf && onOutgoingCall) {
      vonageClient.app.sendDTMF(userUid, dtmf[0])
    }
  }, [callStatus, dtmf, vonageClient.app])

  const answerCall = async () => {
    setDisabledButton(true)
    await vonageClient.app
      .answer(userUid)
      .then(() => dispatch(setAnswerIsClicked(true)))
      .catch(() => dispatch(setAnswerIsClicked(false)))
  }

  const rejectCall = async () => {
    setRejectIsClicked(true)
    setDisabledButton(true)
    const token = await getAccessTokenSilently()
    audio.pause()
    setIsTransferring(true)
    resetCallEvent()
    await transferToVoiceMail({
      token,
      clientUid,
      countryIso2,
      clientNumber: phoneNumber,
    }).catch(() => {
      vonageClient.app.hangup(userUid)
    })
  }

  // Transfer to voicemail if remaining minutes is 0
  useEffect(() => {
    if (clientUid && !isTransferring && clientNumber && countryIso2) {
      if (typeof minutesRemaining === 'number' && minutesRemaining <= 0) {
        rejectCall()
      }
    }
  }, [clientUid, clientNumber, minutesRemaining])

  // transfer to voicemail if don't answering incoming call within 30s
  useEffect(() => {
    if (clientUid) {
      const timer = setTimeout(() => {
        if (
          (callStatus === 'ringing' || callStatus === 'started') &&
          !isTransferring
        ) {
          rejectCall()
        }
      }, 30000)
      return () => clearTimeout(timer)
    }
  }, [clientUid, isTransferring, callStatus])
  const hangUpCall = async () => {
    await vonageClient.app
      .hangup(userUid)
      .then(() => dispatch(setCallStatus('completed')))
  }
  const muteCall = async () => {
    if (!callOnHold) {
      if (callMuted) await vonageClient.app.unmute(userUid)
      else await vonageClient.app.mute(userUid)
      dispatch(setCallMuted(!callMuted))
    }
  }
  const onHoldCall = async () => {
    if (callOnHold) {
      await vonageClient.app.unmute(userUid)
      await vonageClient.app.disableEarmuff(userUid)
    } else {
      await vonageClient.app.mute(userUid)
      await vonageClient.app.enableEarmuff(userUid)
    }
    dispatch(setCallMuted(!callOnHold))
    dispatch(setCallOnHold(!callOnHold))
  }
  const displayDuration = () => {
    let sec = seconds
    let min = minutes
    if (sec < 10) {
      sec = '0' + sec
    }
    if (min < 10) {
      min = '0' + min
    }
    return min + ':' + sec
  }
  if (
    (typeof minutesRemaining === 'number' && minutesRemaining <= 0) ||
    isTransferring ||
    doNotDisturb ||
    (!isMobile && !incomingWeb) ||
    (isMobile && !incomingMobile)
  )
    return null
  if (!callStatus) return null

  const renderIncomingCallAvatar = (size) => {
    if (contactName) {
      return (
        <Avatar
          variant="name"
          staticBackground
          backgroundColor={avatarColorPicker(contactName?.split(' ')[1])}
          name={{
            firstName: contactName?.split(' ')[0] || '',
            lastName: contactName?.split(' ')[1] || '',
          }}
          size={size || 'small'}
        />
      )
    }

    return <AvatarPlaceHolder />
  }

  if (isMobile) {
    return (
      <div id="incoming-call-popout" className="flex flex-col items-center mt-10">
        {renderIncomingCallAvatar('large')}
        {callStatus === 'ringing' && (
          <>
            <span className="font-montserrat font-semibold text-2xl text-black mt-4">
              {contactName
                ? contactName
                : /\d{10}/.test(clientNumber)
                  ? formatContactData(clientNumber)
                  : 'Unknown Caller'}
            </span>
            <div className="flex flex-row gap-[119px] mt-[220px]">
              <div className="flex flex-col h-40 w-18  items-center">
                {/* <span className="h-6 w-6 bg-thryv-gray-medium-100"></span> */}
                <AlarmClockIcon height="24" width="24" />
                <button
                  className="text-xs font-sans text-thryv-black-500 mt-2"
                  disabled
                >
                  Remind Me
                </button>
                <button
                  className="mt-4"
                  onClick={rejectCall}
                  disabled={disabledButton}
                >
                  <RedCircleHangUpCallIcon />
                </button>
                <span className="text-xs font-sans text-thryv-black-500 mt-2">
                  Decline
                </span>
              </div>
              <div className="flex flex-col h-40 w-18 items-center">
                <CallMessageIcon />
                <button
                  className="text-xs font-sans text-thryv-black-500 mt-2"
                  disabled
                >
                  Message
                </button>
                <button
                  className="mt-4"
                  onClick={answerCall}
                  disabled={disabledButton}
                >
                  <GreenCircleIncomingCallIcon />
                </button>
                <button className="text-xs font-sans text-thryv-black-500 mt-2">
                  Accept
                </button>
              </div>
            </div>
          </>
        )}
        {onCall && (
          <>
            <span className="font-montserrat font-semibold text-base text-black mt-4">
              {contactName
                ? contactName
                : /\d{10}/.test(clientNumber)
                  ? formatContactData(clientNumber)
                  : 'Unknown Caller'}
            </span>
            <span className="text-sm font-sans text-thryv-black-500">
              {callStatus === 'answered' && displayDuration()}
              {callStatus === 'completed' && 'Call Ended'}
            </span>
            <div
              className="flex flex-row gap-8 justify-between mt-12"
              disabled={false}
            >
              <button onClick={() => alert('show message')} disabled>
                <CircleMessageIcon />
              </button>
              <div onClick={muteCall} disabled={callStatus === 'completed'}>
                <OutgoingCallMutedIcon
                  width={'64'}
                  height={'64'}
                  fill={callMuted && 'red'}
                />
              </div>
            </div>
            <div className="flex flex-row gap-8 mt-9">
              <div onClick={onHoldCall} disabled={callStatus === 'completed'}>
                <OutgoingCallPauseCallIcon
                  width={'64'}
                  height={'64'}
                  fill={callOnHold && 'red'}
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setDialPadOpen(true)
                }}
                disabled={callStatus === 'completed'}
              >
                <CircleDialPadIcon width={'64'} height={'64'} />
              </div>
            </div>
            <div
              className="w-16 h-16 mt-16"
              onClick={hangUpCall}
              disabled={callStatus === 'completed'}
            >
              <OutgoingCallHangupIcon />
            </div>
          </>
        )}
        {callStatus === 'answered' && !answerIsClicked && !onOutgoingCall && (
          <>
            <span className="font-montserrat font-semibold text-base text-black mt-4">
              {contactName
                ? contactName
                : /\d{10}/.test(clientNumber)
                  ? formatContactData(clientNumber)
                  : 'Unknown Caller'}
            </span>
            <span className="text-sm font-sans text-thryv-black-500">
              Call already answered
            </span>
          </>
        )}
        {callStatus === 'completed' && !answerIsClicked && !onOutgoingCall && (
          <>
            <span className="font-montserrat font-semibold text-base text-black mt-4">
              {contactName
                ? contactName
                : /\d{10}/.test(clientNumber)
                  ? formatContactData(clientNumber)
                  : 'Unknown Caller'}
            </span>
            <span className="text-sm font-sans text-thryv-black-500">
              Call Ended
            </span>
          </>
        )}
      </div>
    )
  }
  return (
    <div id="incoming-call-popout" className="relative">
      {!isMobile && (
        <div>
          {['started', 'ringing'].includes(callStatus) && !dialPadOpen && (
            <div
              className={`relative z-20 w-[300px] border-[#F5BC9E] border-[1px] bg-white shadow-[0_2px_4px_rgba(0,0,0,0.25)] rounded-[10px]`}
            >
              {
                <div className="flex p-[12px]">
                  {renderIncomingCallAvatar()}
                  <div className="flex flex-col pl-[12px]">
                    <span className="flex text-[12px] font-open-sans">
                      {`${callDirection} Call...`}
                    </span>
                    <span className="flex text-[#231F20] text-[12px] font-semibold font-primary">
                      {contactName
                        ? contactName
                        : /\d{10}/.test(clientNumber)
                          ? formatContactData(clientNumber)
                          : 'Unknown Caller'}
                    </span>
                  </div>
                  {!onOutgoingCall ? (
                    <div className="flex gap-[18px] pl-[52px] items-center justify-items-center">
                      <div
                        onClick={answerCall}
                        className="flex cursor-pointer"
                        disabled={disabledButton}
                      >
                        <GreenCircleIncomingCallIcon />
                      </div>
                      <div
                        onClick={rejectCall}
                        className="flex cursor-pointer"
                        disabled={disabledButton}
                      >
                        <RedCircleHangUpCallIcon />
                      </div>
                    </div>
                  ) : null}
                </div>
              }
            </div>
          )}
          {onCall && !expandedView && callStatus === 'answered' && !dialPadOpen && (
            <div
              className={`absolute bg-white z-20 top-[-60px] w-[275px] border-[#F5BC9E] border-[1px] shadow-[0_2px_4px_rgba(0,0,0,0.25)] rounded-[10px]`}
            >
              <div className="flex p-[12px]">
                {renderIncomingCallAvatar()}
                <div className="flex text-[12px] flex-col pl-[10px]">
                  <div className="flex font-open-sans">Ongoing call...</div>
                  <div className="flex text-[#231F20] font-primary font-semibold">
                    {contactName ? contactName : 'Unknown'}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setExpandedView(true)
                  }}
                  className="cursor-pointer flex pl-[63px] pt-[10px]"
                >
                  <FontAwesomeIcon
                    className="text-[#A3A5A7]"
                    icon={faUpRightAndDownLeftFromCenter}
                    size="lg"
                  />
                </div>
                <div
                  onClick={hangUpCall}
                  className="pt-[10px] pl-[12px] cursor-pointer"
                >
                  <RedCircleHangUpCallIcon />
                </div>
              </div>
            </div>
          )}
          {onCall && expandedView && callStatus === 'answered' && !dialPadOpen && (
            <div
              className={`absolute bg-white top-[-151px] z-20 w-[280px] border-[#F5BC9E] border-[1px] shadow-[0_2px_4px_rgba(0,0,0,0.25)] rounded-[10px]`}
            >
              <div className="flex flex-col p-[12px]">
                <div className="flex flex-row">
                  <span className="w-[33px]">{renderIncomingCallAvatar()}</span>
                  <span className="flex w-full text-[#231F20] text-[14px] font-semibold pt-[5px] font-primary pl-[12px]">
                    {contactName ? contactName : 'Unknown'}
                  </span>
                  <div
                    onClick={() => {
                      setExpandedView(false)
                    }}
                    className="cursor-pointer pr-[5px] pt-[5px]"
                  >
                    <FontAwesomeIcon
                      className="text-[#A3A5A7]"
                      icon={faDownLeftAndUpRightToCenter}
                      size="lg"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-thryv-night pl-[4px] pt-[10px] text-[14px] font-semibold font-primary">
                    {/\d{10}/.test(clientNumber)
                      ? formatContactData(clientNumber)
                      : 'Unknown Caller'}
                  </span>
                  <div className="flex text-[14px] pt-[3px] pb-[8px] font-open-sans text-thryv-steel">
                    <span>Ongoing Call</span>
                    <span className="px-[5px]">|</span>
                    <span>{displayDuration()}</span>
                  </div>
                </div>
                <div className="flex flex-row justify-between px-[35px]">
                  <div className="cursor-pointer group" onClick={muteCall}>
                    <CircleMicrophoneSlashIcon active={callMuted} />
                  </div>
                  <div className="cursor-pointer group" onClick={onHoldCall}>
                    <CirclePauseIcon
                      active={callOnHold}
                      width={'32'}
                      height={'32'}
                    />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setDialPadOpen(true)
                    }}
                  >
                    <CircleDialPadIcon />
                  </div>
                  <div className="cursor-pointer" onClick={hangUpCall}>
                    <RedCircleXIcon width={33} height={32} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {callStatus === 'completed' && !dialPadOpen && (
            <div
              className={`absolute bg-white z-20 top-[-60px] w-[275px] border-[#F5BC9E] border-[1px] shadow-[0_2px_4px_rgba(0,0,0,0.25)] rounded-[10px] animate-fadeout`}
            >
              <div className="flex p-[12px]">
                {renderIncomingCallAvatar()}
                <div className="flex text-[12px] flex-col pl-[10px]">
                  <div className="flex font-open-sans">Call ended</div>
                  <div className="flex text-[#231F20] font-primary font-semibold">
                    {contactName ? contactName : 'Unknown'}
                  </div>
                </div>
              </div>
            </div>
          )}
          {onCall && dialPadOpen && callStatus === 'answered' && (
            <div
              id="incoming-call-popout"
              className={`absolute bg-white px-[20px] top-[-482px] pt-[20px] z-50 w-[275px] border-[#F5BC9E] border-[1px] shadow-[0_2px_4px_rgba(0,0,0,0.25)] rounded-[10px]`}
            >
              <div className="flex flex-col gap-[10px]">
                <input
                  value={phoneString}
                  className="pl-[12px] py-[6px] text-[#231F20] font-semibold font-primary flex border-[#E7E7E7] h-[36px] border-[1px] rounded-[5px] w-full"
                  onChange={() => {}}
                />
                <div className="flex">
                  <Keypad
                    sourceIsWidget={true}
                    onChange={(e) => {
                      handleDialPadOnChange(e)
                    }}
                    value={phoneString}
                    setShowKeypad={setDialPadOpen}
                    setEndCall={hangUpCall}
                  />
                </div>
              </div>
            </div>
          )}
          {callStatus === 'answered' && !answerIsClicked && !onOutgoingCall && (
            <div
              className={`absolute bg-white z-20 top-[-60px] w-[275px] border-[#F5BC9E] border-[1px] shadow-[0_2px_4px_rgba(0,0,0,0.25)] rounded-[10px] animate-fadeout`}
            >
              <div className="flex p-[12px]">
                {renderIncomingCallAvatar()}
                <div className="flex text-[12px] flex-col pl-[10px]">
                  <div className="flex font-open-sans">Call ended</div>
                  <div className="flex text-[#231F20] font-primary font-semibold">
                    Call already answered
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default IncomingCallsWidget
