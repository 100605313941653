import { useState, useRef, useContext, useEffect } from 'react'
import '../message-component.css'
import { MessageHeader } from './message-header'
import { MessageFooter } from './message-footer'
import { MessageSocialIcon } from '../message-social-icon'
import ActionButtonsMenu from '../action-buttons-menu/action-buttons-menu'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import MessageSection from './sections/message-section'
import CallAndVoicemailSection from './sections/calls-and-voicemail-section'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import formatContactData from '../../../hooks/formatContactData'
import { IncomingMessageContext } from '../../../../../context/message-context'

const MessageBoxContainer = ({ children, className, onClickOutside, ...rest }) => {
  const messageBoxRef = useRef(null)

  useClickOutside(() => {
    onClickOutside()
  }, messageBoxRef)

  return (
    <div
      className={`
        relative bg-[white] max-w-[465px] md:max-w-[350px] border-solid border border-[#EFEFEF] rounded-bl-xl rounded-br-xl duration-300 transition-all
       
        ${className}
        `}
      {...rest}
      ref={messageBoxRef}
    >
      {children}
    </div>
  )
}
const getExpirationDate = (voicemailDate) => {
  const today = Date.now()
  const oneDay = 24 * 60 * 60 * 1000
  const days = Math.round(Math.abs(today - voicemailDate) / oneDay)
  return days
}

const footerMethods = {
  getFooterText: (sk1, messageType, footerText) => {
    switch (messageType) {
      case 'VOICEMAIL':
        // eslint-disable-next-line no-case-declarations
        const daysToExpired = getExpirationDate(sk1)
        if (daysToExpired >= 30) {
          return 'This voicemail has expired'
        }
        return `Voicemail will expire in ${
          30 - daysToExpired
        } days, but the transcription will remain accessible.`
      default:
        return footerText
    }
  },

  getFooterVariant: (sk1, messageType, footerVariant) => {
    switch (messageType) {
      case 'VOICEMAIL':
        // eslint-disable-next-line no-case-declarations
        const daysToExpired = getExpirationDate(sk1)
        if (daysToExpired >= 27) {
          return 'warning'
        }
        return 'default'
      default:
        return footerVariant
    }
  },
}

export const IncomingMessage = ({
  className,
  hideHeader,
  user,
  message,
  actionButtons,
  footer,
  incomingId,
  labels,
  selectedThreadId,
  refetchPins,
  refetchMessages,
  from,
  messageContentRef,
  positionLabelMenuAbove,
}) => {
  const visibleMenuRef = useRef('')
  const [isDeletedMessage, setIsDeletedMessage] = useState(
    message.text === 'Message deleted.',
  )

  const [showActionButtons, setShowActionButtons] = useState(false)

  const handleMouseEnter = () => {
    if (isDeletedMessage) return
    setShowActionButtons(true)
  }

  const handleMouseLeave = () => {
    if (visibleMenuRef.current === '') setShowActionButtons(false)
  }
  const incomingMessage = useContext(IncomingMessageContext)

  const messageTitleFrom = message.type === 'phone' ? formatContactData(from) : from
  const msgText =
    incomingMessage.body === 'Message deleted.'
      ? incomingMessage.body
      : incomingMessage.body && incomingMessage.email_snippet === null
        ? incomingMessage.body
        : incomingMessage.email_snippet
  const [messageText, setMessageText] = useState(msgText)
  const [messageTitle, setMessageTitle] = useState(
    message.title ? message.title : '',
  )

  useEffect(() => {
    if (messageText && messageText === 'Message deleted.') {
      setMessageTitle('')
      setIsDeletedMessage(true)
      return messageText
    }
  }, [messageText, isDeletedMessage])
  // ignore those duplicate voicemails without a link
  if (message.variant === 'VOICEMAIL' && message.text.indexOf('link') === -1) {
    return null
  }
  return (
    <div id={incomingId} className={`message-container ${className}`}>
      {!hideHeader && (
        <MessageHeader
          name={/\d{10}/.test(user.fullName) ? 'Unknown Contact' : user.fullName}
          imageUrl={user.imageUrl}
          channelAddress={user.channelAddress}
        />
      )}

      <div className={`px-2 pb-1 pt-2`}>
        <MessageBoxContainer
          className={message.type === 'CALL' && 'w-fit'}
          isPinned={message.isPinned}
          disableButtons={actionButtons.allDisabled}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClickOutside={() => setShowActionButtons(false)}
        >
          <div className="flex gap-2.5 absolute -right-2.5 -top-4 items-center h-9">
            <ActionButtonsMenu
              positionLabelMenuAbove={positionLabelMenuAbove}
              setMessageText={setMessageText}
              show={showActionButtons && actionButtons.clickHandlers ? true : false}
              variant={message?.variant}
              type={message?.type}
              disableButtons={actionButtons?.allDisabled}
              showPin={message?.isPinned}
              clickHandlers={actionButtons.clickHandlers}
              refetchMessages={refetchMessages}
              refetchPins={refetchPins}
              labels={message?.labels}
              sk1={message?.sk1}
              pk1={message?.pk1}
              threadpk={message?.threadpk}
              chanpk={message?.chanpk}
              onVisibleMenuChange={(visibleMenu) =>
                (visibleMenuRef.current = visibleMenu)
              }
              incomingId={incomingId}
              messageSk1={message.sk1}
              selectedThreadId={selectedThreadId}
              messageContentRef={messageContentRef}
            />
            {from ? (
              <TooltipWithCenteredArrow
                variant="top"
                width="max-content"
                title={messageTitleFrom}
              >
                <MessageSocialIcon variant={message.type} />
              </TooltipWithCenteredArrow>
            ) : (
              <MessageSocialIcon variant={message.type} />
            )}
          </div>
          {(message.variant === 'MSG' || message.variant === 'SMS') && (
            <MessageSection
              message={message}
              messageText={messageText}
              setMessageTitle={setMessageTitle}
              messageTitle={messageTitle}
              setIsDeletedMessage={setIsDeletedMessage}
              isDeletedMessage={isDeletedMessage}
              messageContentRef={messageContentRef}
            />
          )}
          {(message.variant === 'CALL' || message.variant === 'VOICEMAIL') && (
            <CallAndVoicemailSection message={message} />
          )}
        </MessageBoxContainer>
      </div>
      <MessageFooter
        variant={footerMethods.getFooterVariant(
          incomingMessage.sk1,
          message.variant,
          footer.variant,
        )}
        timestamp={message.timestamp}
        text={footerMethods.getFooterText(
          incomingMessage.sk1,
          message.variant,
          footer.text,
        )}
        id={incomingId}
        labels={isDeletedMessage ? [] : labels}
        isDeletedMessage={isDeletedMessage}
      />
    </div>
  )
}
