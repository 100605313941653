import { useState, useEffect, useRef } from 'react'
import TopNavProfileDropdown from '../../top-navigation-bar/TopNavProfileDropdown'
import { Avatar } from '@thryvlabs/maverick'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { useSocket } from '../../../context/socket-context'
import avatarColorPicker from '../../../utils/avatarColorPicker'

const ProfileAvatar = ({ lastName, firstName, collapsed }) => {
  const TC_BACKEND_DEV_API_URL = 'https://teamchatbackend-staging.thryv.com/'
  const TC_BACKEND_PROD_API_URL = 'https://teamchatproduction.thryv.com/'

  const TEAMCHAT_API_URL =
    import.meta.env.VITE_ENV === 'prod'
      ? TC_BACKEND_PROD_API_URL
      : TC_BACKEND_DEV_API_URL

  const [dropdownActive, setDropdownActive] = useState(false)
  const [online, setOnline] = useState(true)
  const dropdownRef = useRef(null)
  const avatarRef = useRef(null)
  const { doNotDisturb } = useSelector(
    (state) => state.userPreferences.notificationPreferences,
  )
  const { user } = useAuth0()
  const socket = useSocket()

  let thryv_staff_id = user?.staffUID

  // On load, get TC status and update if preferred status is offline
  useEffect(() => {
    if (thryv_staff_id) {
      axios
        .get(`${TEAMCHAT_API_URL}api/teamchat/getstatus/${thryv_staff_id}`)
        .then((res) => {
          const away_status_db = res?.data?.convertedBufferObject?.away_status
          if (
            away_status_db != 'undefined' &&
            away_status_db != '' &&
            away_status_db != null &&
            away_status_db != 0
          ) {
            setOnline(false)
          }
        })
        .catch(() => {})
    }
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !avatarRef.current.contains(event.target)
      ) {
        setDropdownActive(dropdownActive)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  const handleToggleOnlineStatus = () => {
    setOnline(!online)
    if (thryv_staff_id) {
      const awayStatusPayload = {
        thryv_staff_id: thryv_staff_id,
        away_status: online ? 1 : 0,
      }

      axios
        .post(`${TEAMCHAT_API_URL}api/teamchat/awaystatus`, awayStatusPayload)
        .then(() => {})
        .catch(() => {})

      // Connect via socket wih TC backend to emit changed online status
      if (socket) {
        if (online) {
          socket.emit('awayStatus', thryv_staff_id)
        } else {
          socket.emit('user_online', thryv_staff_id)
        }
      }
    }
  }

  const renderImageAvatar = !user?.picture?.includes('auth0') ? (
    <Avatar
      data-testid="image-profile-avatar"
      variant="image"
      size="default"
      imageUrl={user.picture}
      className="relative cursor-pointer"
      onClick={() => setDropdownActive(!dropdownActive)}
    />
  ) : null

  const renderNamedAvatar = user?.picture?.includes('auth0') ? (
    <Avatar
      data-testid="named-profile-avatar"
      variant="name"
      size="default"
      staticBackground
      name={{ firstName, lastName }}
      className="relative cursor-pointer"
      onClick={() => setDropdownActive(!dropdownActive)}
      backgroundColor={avatarColorPicker(lastName[0])}
    />
  ) : null

  return (
    <>
      <div className="relative" data-testid="top-nav-profile-avatar" ref={avatarRef}>
        {renderImageAvatar}
        {renderNamedAvatar}
        <div
          id="profile-online-indicator"
          className={`${
            collapsed ? 'h-[12.75px] w-[12.75px]' : 'h-[15px] w-[15px]'
          } ${
            online ? 'bg-[#16A085]' : 'bg-[#808080]'
          }  rounded-full border-2 border-white absolute md:top-[30px] md:-right-[4px] min-w-md:top-[28px] min-w-md:-right-[3.5px]`}
        ></div>
        {doNotDisturb && (
          <div
            id="profile-notifications-indicator"
            className={`font-black ${online ? 'text-[#16A085]' : 'text-[#808080]'} ${
              collapsed ? 'w-[6px] h-[6.5px]' : 'w-[10px] h-[10.5px]'
            } rounded-sm bg-white absolute md:top-[28px] md:-right-[4px] min-w-md:top-[25.5px] min-w-md:-right-[8px] sm:top-[28px]`}
          >
            <span
              className={`absolute font-bold ${
                collapsed ? 'text-[9px] -top-[4.5px]' : 'text-[12px] -top-[4.5px]'
              } -top-[4.5px] left-[.5px]`}
            >
              z
            </span>
          </div>
        )}
        {!dropdownActive ? null : (
          <div ref={dropdownRef} data-testid="profile-avatar-dropdown">
            <TopNavProfileDropdown
              online={online}
              handleToggleOnlineStatus={handleToggleOnlineStatus}
              setDropdownActive={setDropdownActive}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ProfileAvatar
