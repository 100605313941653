import { useEffect, useState } from 'react'
import VoicemailContent from './VoicemailContent'
import VoicemailContentMobile from './VoicemailContentMobile'
import { selectContact } from '../../../../../redux-toolkit/slices/calls/contacts/contacts-slice'
import { useDispatch } from 'react-redux'

const Voicemail = ({
  isMobile,
  setDisplayInfoCard,
  body,
  agent_id,
  item_type,
  number_from,
  sk1,
  pk1,
  number_to,
  threadpk,
  ts,
  setContactInfoView,
  msgpk,
  chanpk,
}) => {
  const [voiceMailOpen, setVoicemailOpen] = useState(false)
  const dispatch = useDispatch()

  const voicemail = {
    body,
    agent_id,
    item_type,
    number_from,
    sk1,
    pk1,
    number_to,
    threadpk,
    ts,
    msgpk,
    chanpk,
  }

  const getTranscript = (voicemail) => {
    try {
      if (JSON.parse(voicemail?.body).transcription) {
        return JSON.parse(voicemail?.body).transcription
      }
    } catch (error) {
      // console.error(error)
    }
    try {
      if (voicemail?.body.match(/transcription=(.*?)(?:\}|$)/).length > 0) {
        return voicemail?.body
          .match(/transcription=(.*?)(?:\}|$)/)[1]
          .split(', link=https:')[0]
      } else if (JSON.parse(voicemail?.body).includes('transcription')) {
        return JSON.parse(voicemail?.body)
          ?.match(/transcription=(.*?)(?:\}|$)/)?.[1]
          .split(', link=https:')[0]
      } else {
        return 'No transcript available'
      }
    } catch (error) {
      return 'No transcript available'
    }
  }

  const getLink = (voicemail) => {
    try {
      if (JSON.parse(voicemail?.body).link) {
        return JSON.parse(voicemail?.body).link
      }
    } catch (error) {
      // console.error(error)
    }
    try {
      if (voicemail?.body.match(/link=(https?:\/\/[^}]+)/).length > 0) {
        return voicemail?.body
          .match(/link=(https?:\/\/[^}]+)/)[1]
          .split(', transcription=')[0]
      } else if (JSON.parse(voicemail?.body).includes('link')) {
        return JSON.parse(voicemail?.body)
          ?.match(/link=(https?:\/\/[^}]+)/)?.[1]
          .split(', transcription=')[0]
      } else {
        return 'No link available'
      }
    } catch (error) {
      return 'No link available'
    }
  }

  useEffect(() => {
    setVoicemailOpen(false)
  }, [isMobile])

  function handleClick() {
    dispatch(
      selectContact({
        recentCall: { ...voicemail, phone: voicemail?.number_from },
      }),
    )

    setContactInfoView('default')
  }

  return (
    <table className="w-full">
      <tbody className="w-full">
        <tr
          onClick={handleClick}
          className={`flex flex-col active:bg-thryv-cloud hover:bg-[#F2F4F6] hover:cursor-pointer group md:px-[5px] justify-center h-[74px] w-full rounded-[5px] font-open-sans mb-[5px]`}
        >
          <td className={'flex items-center flex-wrap'}>
            {isMobile ? (
              <VoicemailContentMobile
                voicemail={voicemail}
                voiceMailOpen={voiceMailOpen}
                setDisplayInfoCard={setDisplayInfoCard}
              />
            ) : (
              <VoicemailContent
                voicemail={voicemail}
                voiceMailOpen={voiceMailOpen}
                setVoicemailOpen={setVoicemailOpen}
                voicemailLink={getLink(voicemail)}
              />
            )}
          </td>
        </tr>
        <tr
          className={`${
            voiceMailOpen ? '' : 'collapse'
          } text-thryv-night bg-[#f8f9fb]`}
        >
          <td className="flex flex-row p-[15px] rounded-[5px]">
            <p className="flex w-full px-[10px]">{getTranscript(voicemail)}</p>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default Voicemail
