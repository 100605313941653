import { forwardRef, useState, useRef, useEffect } from 'react'
import { useKnockFeed } from '@knocklabs/react'
import { Button } from '@thryvlabs/maverick'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useInboxMenuConversations } from '../../inbox/inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { useMutation, useLazyQuery } from '@apollo/client'
import { FETCH_THREADS, UPDATE_THREAD_FIELD } from '../../../graphql'
import { setSelectedThread } from '../../inbox/slices/inboxThreadsSlice'
import NotificationFeed from './NotificationFeed'

const NotificationContainer = forwardRef(({ isVisible, setIsVisible }, ref) => {
  const dropdownRef = useRef(null)
  const notificationRef = useRef(null)
  const menuRef = useRef(null)
  const isFirstRender = useRef(true)
  const [viewMoreClicked, setViewMoreClicked] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { feedClient, useFeedStore } = useKnockFeed()
  const { items } = useFeedStore()

  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()
  const [updateToRead] = useMutation(UPDATE_THREAD_FIELD, {})

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    fetchPolicy: 'no-cache',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const openNotification = (notification) => {
    updateToRead({
      variables: {
        sk1s: notification.data.typeId.sk1,
        field: 'read',
      },
      onCompleted: () => {
        getThreads()
      },
    })

    dispatch(
      setSelectedThread({
        id: notification.data.threadId,
        name: notification.data.subject,
      }),
    )

    setShowMenu(false)
    setIsVisible(false)

    if (!window.location.href.includes('/inbox')) {
      navigate('/inbox')
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      const unread = items.filter(
        (x) => x.data.threadId === selectedThreadId && !x.read_at,
      )
      if (unread.length > 0) {
        feedClient.markAsRead(unread)
      }
    }
  }, [selectedThreadId])

  useEffect(() => {
    if (window.location.pathname.includes('/voicemail')) {
      const voicemailNotifications = items.filter(
        (x) => x.data.type === 'voicemail' && !x.read_at,
      )
      if (voicemailNotifications.length > 0) {
        feedClient.markAsRead(voicemailNotifications)
      }
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (window.location.pathname === '/calls') {
      const callsNotifications = items.filter(
        (x) => x.data.type === 'missed_call' && !x.read_at,
      )
      if (callsNotifications.length > 0) {
        feedClient.markAsRead(callsNotifications)
      }
    }
  }, [window.location.pathname])

  const markAsRead = (item) => {
    feedClient.markAsRead(item)
  }

  const markAsUnread = (item) => {
    feedClient.markAsUnread(item)
  }

  const clearAll = () => {
    feedClient.markAsArchived(items)
  }

  const clearSingleNotification = (item) => {
    feedClient.markAsArchived(item)
  }

  useEffect(() => {
    feedClient.fetch()
  }, [feedClient])

  useEffect(() => {
    if (isVisible && items.length > 0) {
      feedClient.markAsSeen(items)
    }
  }, [isVisible])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !ref?.current.contains(event.target) &&
        (!menuRef?.current?.contains(event.target) || !menuRef.current)
      ) {
        setIsVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef, menuRef])

  return (
    <>
      {isVisible && (
        <div
          data-testid="notification-container"
          ref={dropdownRef}
          className="max-w-[396px] bg-white border-[#f8f9fb] drop-shadow-2xl w-[396px] absolute top-[75px] z-[1000] right-[8px] rounded-[8px] "
        >
          <div className="flex flex-col drop-shadow-sm rounded-[8px]">
            <div className="h-[46px] w-full bg-[#f8f9fb] flex justify-between items-center">
              <h6 className="font-open-sans font-bold text-[14px] px-[16px] py-[13px] leading-5 text-[#4d4d4d]">
                All Notifications
              </h6>

              <Button
                variant="text"
                level={1}
                className="px-[16px]"
                onClick={clearAll}
                disabled={items.length === 0}
              >
                {items.length > 1 ? 'Clear All' : 'Clear'}
              </Button>
            </div>
          </div>
          {viewMoreClicked ? (
            <>
              {items.length === 0 ? (
                <div className="flex flex-col gap-2 items-center justify-center py-4">
                  <h6 className="font-[montserrat] font-semibold text-[14px] leading-5 text-[#231f20]">
                    No Notifications
                  </h6>
                  <h6 className="font-open-sans font-normal text-[14px] leading-5 text-[#4d4d4d]">
                    We'll notify you when there is something new.
                  </h6>
                </div>
              ) : (
                <div
                  ref={notificationRef}
                  className=" max-h-[70vh] relative overflow-y-scroll"
                  data-testid="notification-feed-view-more"
                >
                  {items.map((notification, idx) => {
                    return (
                      <>
                        <NotificationFeed
                          key={idx}
                          notification={notification}
                          markAsRead={markAsRead}
                          viewMoreClicked={viewMoreClicked}
                          clearSingleNotification={clearSingleNotification}
                          markAsUnread={markAsUnread}
                          setIsVisible={setIsVisible}
                          showMenu={showMenu}
                          setShowMenu={setShowMenu}
                          index={idx}
                          items={items}
                          openNotification={openNotification}
                          allRefs={{ notificationRef, menuRef }}
                        />
                      </>
                    )
                  })}
                </div>
              )}
            </>
          ) : (
            <>
              {items.length > 0 ? (
                <div
                  className="max-h-[585px]"
                  data-testid="notification-feed-collapsed"
                >
                  {items.slice(0, 5).map((notification, idx) => {
                    return (
                      <NotificationFeed
                        key={idx}
                        notification={notification}
                        markAsRead={markAsRead}
                        viewMoreClicked={viewMoreClicked}
                        clearSingleNotification={clearSingleNotification}
                        markAsUnread={markAsUnread}
                        setIsVisible={setIsVisible}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        index={idx}
                        items={items}
                        openNotification={openNotification}
                        allRefs={{ notificationRef, menuRef }}
                      />
                    )
                  })}
                </div>
              ) : (
                <div className="flex flex-col gap-2 items-center justify-center py-4">
                  <h6 className="font-[montserrat] font-semibold text-[14px] leading-5 text-[#231f20]">
                    No Notifications
                  </h6>
                  <h6 className="font-open-sans font-normal text-[14px] leading-5 text-[#4d4d4d]">
                    We'll notify you when there is something new.
                  </h6>
                </div>
              )}
            </>
          )}
          {items.length > 5 ? (
            <div
              data-testid="view-more-button"
              className="h-[46px] w-full bg-[#f3f7ff] flex justify-center items-center"
            >
              <Button
                variant="text"
                level={1}
                className="px-[16px]"
                onClick={() => setViewMoreClicked(!viewMoreClicked)}
              >
                {viewMoreClicked ? 'View Less' : 'View More'}
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
})

export default NotificationContainer
