import { useState } from 'react'
import { Avatar, Button, Header, Icon } from '@thryvlabs/maverick'
import {
  ContactPhoneButtonIcon,
  SelectedContactEmptyState,
  ContactFaceTimeButtonIcon,
} from '@icons'
import { CommandCenterIcon } from '../../command-center-icon/command-center-icon'
import { useRedux } from '@hooks'
import AddContactPanel from './add-contact-panel/AddContactPanel'
import EditContactPanel from './edit-contact-panel/EditContactPanel'
import RecentCalls from './recentcall-section/RecentCalls'
import {
  setPrepopulatedNumber,
  setPrepopulatedNumberClicked,
} from '../../calls/slices/callSlice'
import { useSelector, useDispatch } from 'react-redux'
import phoneFormatter from '../../../utils/phoneFormatter'
import avatarColorPicker from '../../../utils/avatarColorPicker'
import { setOpenVideoCallLinkModal } from '../slices/callSlice'
import { ThryvGuyIcon, RemoveContactDetail } from '../../command-center-icon/icons'
import formatContactData from '../../inbox/hooks/formatContactData'
import {
  GET_CONNECTED_CHANNELS_BY_USER,
  FETCH_CONTACT_BY_THREAD,
} from '../../../graphql'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { getThryvSupportNumber } from '../../../utils/get-thryv-support-number'
import { FETCH_THREADS } from '../../../graphql'
import { useInboxMenuConversations } from '../../inbox/inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'

const ContactDetailsButton = ({ type = 'phone' }) => {
  const { prepopulatedNumberClicked } = useSelector((state) => state.calls)
  const { selectedContact } = useSelector((state) => state.contacts)
  const dispatch = useDispatch()
  const { phone } = selectedContact

  const { user } = useAuth0()
  const [isPhoneActive, setIsPhoneActive] = useState(true)
  const [isVideoActive, setIsVideoActive] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [isInboxActive, setIsInboxActive] = useState(true)

  const { data } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
    onCompleted: () => {
      const phoneChannels = data.queryConnectedChannelsByUser.filter(
        (x) => x.Status !== '99' && x.Status !== '0' && x.Provider === 'phone',
      )
      const videoChannels = data.queryConnectedChannelsByUser.filter(
        (x) => x.Status !== '99' && x.Status !== '0' && x.Provider === 'video',
      )
      const inboxChannels = data.queryConnectedChannelsByUser.filter(
        (x) => x.Status !== '99' && x.Status !== '0' && x.VendorID === '1',
      )

      if (videoChannels.length === 0) {
        setIsVideoActive(false)
      }
      if (phoneChannels.length === 0) {
        setIsPhoneActive(false)
      }
      if (inboxChannels.length === 0) {
        setIsInboxActive(false)
      }
    },
  })

  const renderPhoneButton =
    type === 'phone' ? (
      <div
        data-testid="phone-button"
        onClick={() => {
          if (isPhoneActive) {
            dispatch(setPrepopulatedNumber(phone ? phoneFormatter(phone) : ''))
            dispatch(setPrepopulatedNumberClicked(!prepopulatedNumberClicked))
          }
        }}
        className="w-10 flex flex-col gap-2 item-center justify-start cursor-pointer"
      >
        <ContactPhoneButtonIcon enabled={isPhoneActive} />
        <span
          className={
            isPhoneActive
              ? 'font-open-sans text-sm font-normal ml-[6px]'
              : 'font-open-sans text-sm font-normal ml-[6px] opacity-[0.3]'
          }
        >
          Call
        </span>
      </div>
    ) : null

  const renderVideoButton =
    type === 'video' ? (
      <div
        data-testid="video-button"
        onClick={() => {
          if (isVideoActive) {
            dispatch(setOpenVideoCallLinkModal(true))
          }
        }}
        className="w-10 flex flex-col gap-2 item-center justify-start cursor-pointer"
      >
        <ContactFaceTimeButtonIcon enabled={isVideoActive} />
        <span
          className={
            isVideoActive
              ? 'font-open-sans text-sm font-normal'
              : 'font-open-sans text-sm font-normal opacity-[0.3]'
          }
        >
          Video
        </span>
      </div>
    ) : null

  return (
    <>
      {renderPhoneButton}
      {renderVideoButton}
    </>
  )
}

const OverwriteContactButtons = ({
  connection,
  selectedContact,
  handleCreateNewContact,
}) => {
  const { username, phone } = selectedContact
  return (
    <>
      {connection && !username && phone ? (
        <div className="flex flex-col items-center">
          <Button
            variant="secondary"
            className="my-4 w-[178px] h-7 text-[11px] leading-[14px] break-normal"
            onClick={handleCreateNewContact}
          >
            CREATE NEW CONTACT
          </Button>
        </div>
      ) : null}
    </>
  )
}

const EditContactButton = ({
  setContactInfoView,
  clearEmail,
  clearPhone,
  setClearEmail,
  setClearPhone,
  handleEditEmail,
}) => {
  return (
    <div className="w-full flex flex-row justify-between items-center mt-[2px]">
      <Header className="md:hidden" fontWeight="semibold" variant="h7">
        Contact Card
      </Header>

      <button className="flex grow justify-end" data-testid="edit-contact-btn">
        <CommandCenterIcon
          variant="circlePen"
          type="solid"
          color="#A3A5A7"
          height="24"
          width="24"
          onClick={() => {
            setClearEmail(!clearEmail)
            setClearPhone(!clearPhone)
            setContactInfoView('editContactPanel')
            handleEditEmail()
          }}
        />
      </button>
    </div>
  )
}

const ContactDetails = ({
  selectedContact,
  setContactInfoView,
  setMobileContactSideSheetOpen,
  clearEmail,
  clearPhone,
  setClearEmail,
  setClearPhone,
  openEditContact,
  showMobileContact,
  setShowMobileContact,
  handleEditEmail,
  handleEditPhone,
}) => {
  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: {
      threadid: selectedContact?.threadpk?.split('#')?.at(0),
    },
  })

  const firstName =
    contact?.queryContactsByThread?.items?.at(0)?.body &&
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).given_name
      : ''
  const lastName =
    contact?.queryContactsByThread?.items?.at(0)?.body &&
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).surname
      : ''

  const username =
    selectedContact.username === 'Thryv Support Center'
      ? 'Thryv Support Center'
      : /\d{10}/.test(firstName)
        ? 'Unknown'
        : firstName?.length > 0 && lastName?.length === 0
          ? firstName
          : firstName?.length > 0 && lastName?.length > 0
            ? firstName + ' ' + lastName
            : 'Unknown'

  return (
    <>
      {openEditContact && !showMobileContact && (
        <div className="absolute top-[25px] left-[27px] flex flex-row items-center min-w-md:hidden">
          <button
            className="ml-3 text-sm font-montserrat font-semibold"
            onClick={() => {
              setMobileContactSideSheetOpen(false)
            }}
          >
            <Icon
              type="solid"
              variant="chevronLeft"
              height="16.84"
              width="8.7"
              color="#4D4D4D"
            />
            Back
          </button>
        </div>
      )}
      <div
        className="w-full flex flex-col gap-4 items-center justify-start border-b border-barely-gray pb-6"
        data-testid="contact-details"
      >
        {/* displayed edit icon if the selected contact is not an unkown contact */}
        {contact &&
        contact?.queryContactsByThread?.items?.at(0)?.email?.toLowerCase() !==
          'support@thryv.com' ? (
          <EditContactButton
            setContactInfoView={setContactInfoView}
            clearEmail={clearEmail}
            clearPhone={clearPhone}
            setClearEmail={setClearEmail}
            setClearPhone={setClearPhone}
            showMobileContact={showMobileContact}
            setShowMobileContact={setShowMobileContact}
            handleEditEmail={handleEditEmail}
            handleEditPhone={handleEditPhone}
          />
        ) : (
          <></>
        )}

        {contact &&
        contact?.queryContactsByThread?.items?.at(0)?.email?.toLowerCase() !==
          'support@thryv.com' ? (
          <Avatar
            variant={
              username && !/\d{10}/.test(username) && username !== 'Unknown'
                ? 'name'
                : 'unidentified'
            }
            size="large"
            name={{ firstName, lastName } || null}
            staticBackground
            backgroundColor={avatarColorPicker(lastName?.at(0))}
          />
        ) : (
          <ThryvGuyIcon width={'72'} height={'72'} />
        )}

        <div className="flex flex-col gap-1 w-full items-center justify-start">
          <span className="font-montserrat font-semibold text-base">
            {username || 'Unknown'}
          </span>
          {/* <span className="font-open-sans font-normal text-sm">{job}</span> */}
        </div>
        <div className="w-full flex gap-8 items-center justify-center">
          <ContactDetailsButton />
          <ContactDetailsButton type="inbox" />
          <ContactDetailsButton type="video" />
        </div>
        <ContactDetailsButton type="videoCall" />
      </div>
    </>
  )
}

const UserInfo = ({
  title,
  info,
  clearEmail,
  clearPhone,
  handleEditEmail,
  handleEditPhone,
}) => {
  const handleEditInfo = () => {
    if (clearEmail) {
      handleEditEmail()
      handleEditPhone()
    }

    if (clearPhone) {
      handleEditPhone()
      handleEditEmail()
    }
  }
  const renderEditInfoBtn =
    clearEmail || clearPhone ? (
      <button
        onClick={handleEditInfo}
        data-testid={
          clearEmail ? 'edit-contact-email-btn' : 'edit-contact-phone-btn'
        }
      >
        <RemoveContactDetail />
      </button>
    ) : null

  return (
    <div className="w-full flex flex-col gap-1 items-start justify-start">
      <span className="font-montserrrat text-sm font-semibold">{title}</span>
      <div className="w-full flex justify-between items-center">
        <span className="font-open-sans text-sm text-[#808080]">{info}</span>
        {renderEditInfoBtn}
      </div>
    </div>
  )
}

const ContactInfo = ({
  selectedContact,
  contact,
  clearEmail,
  clearPhone,
  handleEditEmail,
  handleEditPhone,
}) => {
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const formattedPhoneNumber = contact
    ? contact?.queryContactsByThread?.items?.at(0)?.contactsk3 === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(body?.phone_numbers?.at(0)?.number)
    : null

  const contactCheck = () => {
    if (selectedContact.username === 'Thryv Support Center') {
      return phoneFormatter(getThryvSupportNumber(countryISO))
    } else {
      return contact ? formattedPhoneNumber : '-'
    }
  }
  const emailCheck = () => {
    if (selectedContact.username === 'Thryv Support Center') {
      return 'support@thryv.com'
    }
    if (
      typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string' &&
      contact?.queryContactsByThread?.items?.at(0)?.body !== 'null'
    ) {
      //parse thru the body field and get emails
      const parsedBody = JSON.parse(
        contact?.queryContactsByThread?.items?.at(0)?.body,
      )
      return parsedBody?.emails.length === 0 ? '-' : parsedBody.emails?.at(0).email
    }
    return '-'
  }

  return (
    <div className="flex flex-col gap-4 items-start justify-start border-b border-barely-gray pb-6">
      <UserInfo
        title="Phone"
        info={contactCheck()}
        clearPhone={clearPhone}
        handleEditPhone={handleEditPhone}
      />
      <UserInfo
        title="Email"
        info={emailCheck()}
        clearEmail={clearEmail}
        handleEditEmail={handleEditEmail}
      />
    </div>
  )
}

export const ContactInfoView = ({
  connection,
  setMobileContactSideSheetOpen,
  refetchContacts,
  setContactInfoView,
  showMobileContact,
  setShowMobileContact,
}) => {
  const [{ selectedContact }] = useRedux('contacts')
  const [isEditing, setIsEditing] = useState(false)
  const [openEditContact, setOpenEditContact] = useState(false)
  const [enablePhoneInput, setEnablePhoneInput] = useState(false)
  const [enableEmailInput, setEnableEmailInput] = useState(false)
  const [clearPhone, setClearPhone] = useState(false)
  const [clearEmail, setClearEmail] = useState(false)
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const handleEditPhone = () => {
    setEnablePhoneInput(true)
    setEnableEmailInput(false)
    setOpenEditContact(true)
    if (setShowMobileContact) {
      setShowMobileContact(false)
    }
  }

  const handleEditEmail = () => {
    setEnableEmailInput(true)
    setEnablePhoneInput(false)
    setOpenEditContact(true)
    if (setShowMobileContact) {
      setShowMobileContact(false)
    }
  }

  const handleCreateNewContact = () => {
    setContactInfoView('editContactPanel')
    setOpenEditContact(true)
  }

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    fetchPolicy: 'no-cache',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const { data: contact, refetch } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: selectedContact?.threadpk?.split('#')[0] },
  })

  const firstName = contact
    ? JSON.parse(contact?.queryContactsByThread?.items[0].body).given_name
    : ''
  const lastName = contact
    ? JSON.parse(contact?.queryContactsByThread?.items[0].body).surname
    : ''

  const username =
    selectedContact.username === 'Thryv Support Center'
      ? 'Thryv Support Center'
      : /\d{10}/.test(firstName)
        ? 'Unknown'
        : firstName?.length > 0 && lastName?.length === 0
          ? firstName
          : firstName?.length > 0 && lastName?.length > 0
            ? firstName + ' ' + lastName
            : 'Unknown'

  const renderSelectedContactEmptyState = !connection ? (
    <div className="w-full flex flex-col items-center justify-center gap-6 mt-[63px]">
      <SelectedContactEmptyState />
      <div className="w-[267px] flex flex-col gap-5 items-center justify-center">
        <span className='w-full "font-open-sans text-sm text-[#808080] font-normal text-center'>
          Your contact cards will appear here after you get a phone number or connect
          Inbox channels.
        </span>
        {/* <Button variant="secondary">CONNECT CHANNELS</Button> */}
      </div>
    </div>
  ) : null

  const renderSelectedContatct = connection ? (
    <div className="w-full flex flex-col gap-6">
      <ContactDetails
        selectedContact={selectedContact}
        setContactInfoView={setContactInfoView}
        setMobileContactSideSheetOpen={setMobileContactSideSheetOpen}
        clearEmail={clearEmail}
        clearPhone={clearPhone}
        setClearEmail={setClearEmail}
        setClearPhone={setClearPhone}
        openEditContact={openEditContact}
        showMobileContact={showMobileContact}
        setShowMobileContact={setShowMobileContact}
        handleEditEmail={handleEditEmail}
        handleEditPhone={handleEditPhone}
      />
      <ContactInfo
        selectedContact={selectedContact}
        contact={contact}
        clearEmail={clearEmail}
        clearPhone={clearPhone}
        handleEditEmail={handleEditEmail}
        handleEditPhone={handleEditPhone}
      />
    </div>
  ) : null

  const renderSelectedContatctView = !openEditContact ? (
    <div className="p-6 w-[350px] h-full md:w-full bg-white absolute border-x border-[#F1F1F5] md:border-none overflow-y-auto no-scrollbar">
      {renderSelectedContactEmptyState}
      {renderSelectedContatct}
      {username === 'Thryv Support Center' || username === 'Unknown' ? (
        <OverwriteContactButtons
          connection={connection}
          selectedContact={selectedContact}
          handleCreateNewContact={handleCreateNewContact}
        />
      ) : (
        <></>
      )}
      {connection && <RecentCalls selectedContact={selectedContact} />}
    </div>
  ) : null

  const renderEditContactPanel = openEditContact ? (
    <EditContactPanel
      selectedContact={selectedContact}
      setContactInfoView={setContactInfoView}
      contact={contact}
      refetch={refetch}
      refetchContacts={refetchContacts}
      editingCall={true}
      contactName={username}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      enableEmailInput={enableEmailInput}
      enablePhoneInput={enablePhoneInput}
      handleEditPhone={handleEditPhone}
      handleEditEmail={handleEditEmail}
      setIsEditPanelOpen={setOpenEditContact}
      setClearEmail={setClearEmail}
      setClearPhone={setClearPhone}
      setShowMobileContact={setShowMobileContact}
      getThreads={getThreads}
    />
  ) : null

  return (
    <>
      <AddContactPanel setContactInfoView={setContactInfoView} />
      {renderEditContactPanel}
      {renderSelectedContatctView}
    </>
  )
}

export default ContactInfoView
