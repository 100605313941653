import moment from 'moment'
import { useState, useRef, useEffect, useContext } from 'react'
import {
  OutgoingCallButton,
  PlayButton,
  ExpandCollapseButton,
} from './voicemail-buttons'
import { VoicemailTranscript } from './voicemail-transcript'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPrepopulatedNumber } from '../../../../../calls/slices/callSlice'
import phoneFormatter from '../../../../../../utils/phoneFormatter'
import './voicemail-section-styles.css'

import { RenderWaveSurfer } from './render-waveForm'
import { IncomingMessageContext } from '../../../../../../context/message-context'
import { useMediaQuery } from 'react-responsive'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_S3_DOWNLOAD_LINK } from '@graphql'

// const fs = require('fs')

/* ----- Reference ---- */
/* https://stackoverflow.com/questions/31337370/how-to-convert-seconds-to-hhmmss-in-moment-js */
export const formatSecondsToMinutes = (seconds) => {
  return moment.utc(seconds * 1000).format('mm:ss')
}

export const formatDateReceived = (date) => {
  return moment(date).local().format('ddd, MMM MM')
}

export const VoicemailSection = ({ className, voicemail }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const isMobile = useMediaQuery({ maxWidth: 835 })

  const filename = `${user.cc_id}/${voicemail.chanpk}/${
    voicemail.threadpk.split('#')[0]
  }/${voicemail.msgpk}.mp3`

  const { data } = useQuery(GET_S3_DOWNLOAD_LINK, {
    variables: {
      filename,
    },
  })

  // transcript states
  const [transcript, setTranscript] = useState('')
  const [showTranscript, setShowTranscript] = useState(false)
  //link state
  const [linkToFetchAudio, setLinkToFetchAudio] = useState('')
  const [voicemailExpired, setVoicemailExpired] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)
  const [audioLoaded, setAudioLoaded] = useState(false)
  const [voicemailAudio, setVoicemailAudio] = useState('')
  const [replayAudio, setReplayAudio] = useState(false)
  const [loading, setLoading] = useState(false)
  const waveformRef = useRef(null)

  const incomingMessageData = useContext(IncomingMessageContext)

  const formatLink = (linkString) => {
    const formattedLink = linkString.substring(
      linkString.indexOf('link=') + 5, //formatting to get the download url after the = sign.
      linkString.length - 1, //this removes the curly bracket at the end of the string
    )
    return formattedLink
  }

  const formatVoicemailText = () => {
    try {
      const parsedData = JSON.parse(voicemail.text)
      setTranscript(parsedData.transcription)
    } catch (error) {
      const voicemailText = voicemail.text

      // If both transcription and link are available
      if (
        voicemailText.includes('transcription') &&
        voicemailText.includes('link')
      ) {
        const transcription = voicemailText.substring(
          voicemailText.indexOf('=') + 1,
          voicemailText.indexOf('link=') - 2,
        )
        const link = formatLink(voicemailText)
        setTranscript(transcription)
        setLinkToFetchAudio(link)
        return link

        // If only transcription is available
      } else if (voicemailText.includes('transcription')) {
        const transcription = voicemailText.substring(voicemailText.indexOf('=') + 1)
        const link = formatLink(voicemailText)
        setTranscript(transcription)
        setLinkToFetchAudio(link)

        // If no transcription is available
      } else {
        setTranscript('No transcription available.')
        const link = formatLink(voicemailText)
        setLinkToFetchAudio(link)
        return link
      }
    }
  }

  const getExpirationDate = (voicemailDate) => {
    const today = Date.now()
    const oneDay = 24 * 60 * 60 * 1000
    const days = Math.round(Math.abs(today - voicemailDate) / oneDay)

    if (days >= 30) setVoicemailExpired(true)
    else setVoicemailExpired(false)
    return days
  }

  const init = async () => {
    await formatVoicemailText()
    getExpirationDate(voicemail?.sk1)
  }

  useEffect(() => {
    init()
  }, [])

  const { VITE_NYLAS_HOST } = import.meta.env

  const fetchVoicemailAudio = async () => {
    const audioUUID = linkToFetchAudio.substring(
      linkToFetchAudio.indexOf('files/') + 6,
    )
    const myHeaders = new Headers()
    myHeaders.append('Accept', 'audio/mpeg')

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const s3DownloadLink = data?.getSafeS3DownloadURL.substring(
      data?.getSafeS3DownloadURL.indexOf('=') + 1,
      data?.getSafeS3DownloadURL.length - 1,
    )

    const expectedResponse = await fetch(s3DownloadLink, requestOptions)

    if (expectedResponse.ok) {
      return await expectedResponse.blob()
    } else {
      const vonageResponse = await fetch(
        `${VITE_NYLAS_HOST}/voicemail/${audioUUID}`,
        requestOptions,
      )
      return await vonageResponse.blob()
    }
  }

  const handlePlayButtonClick = async () => {
    if (!audioLoaded) {
      setLoading(true)
      const audio = await fetchVoicemailAudio()
      setVoicemailAudio(audio)
      setAudioLoaded(true)
    } else {
      //this is for replaying the audio already loaded
      setReplayAudio(!replayAudio)
    }
  }

  const handleOutgoingCallButtonClick = () => {
    const missedCallPhoneNumber = incomingMessageData.number_from
    dispatch(setPrepopulatedNumber(missedCallPhoneNumber))
    navigate('/calls')
  }

  return (
    <div
      className={`${className} justify-evenly px-2 ${isMobile ? 'w-[320px]' : ''}`}
    >
      <div className="flex items-center">
        <OutgoingCallButton
          className="mr-2.5"
          onClick={handleOutgoingCallButtonClick}
        />
        <PlayButton
          className="mr-4"
          onClick={() => {
            handlePlayButtonClick()
          }}
          disabled={voicemailExpired}
          showPauseButton={isPlaying}
        />
        {loading && !audioLoaded ? (
          <div className="flex items-center h-[28px]">
            <div
              className={`absolute voicemail-indeterminate-progress-bar opacity-100 left-30`}
            >
              <div className="voicemail-indeterminate-progress-bar__progress"></div>
            </div>
          </div>
        ) : !audioLoaded ? (
          <div className="w-[50%] bg-[#d1d5db] rounded h-1 relative overflow-hidden mr-[8px]"></div>
        ) : (
          <RenderWaveSurfer
            setIsPlaying={setIsPlaying}
            audio={voicemailAudio}
            ref={waveformRef}
            replayAudio={replayAudio}
            audioLoading={loading}
            setLoading={setLoading}
          />
        )}
        <ExpandCollapseButton
          className={`${loading ? 'absolute right-10' : null}`}
          disabled={transcript === '' ? true : false}
          onClick={() => setShowTranscript(!showTranscript)}
          active={showTranscript}
        />
      </div>

      <VoicemailTranscript
        show={showTranscript}
        phoneNumber={phoneFormatter(incomingMessageData.number_from)}
        dateReceived={moment(voicemail.sk1).local().format('LL')}
        transcript={transcript}
      />
    </div>
  )
}
