import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Header } from '@thryvlabs/maverick'
import AddPaymentMethod from './PaymentMethod/AddPaymentMethod'
import CustomModal from '../../../components/common/CustomModal/Modal'
import SavedPayment from './PaymentMethod/SavedPayment'
import { useMediaQuery } from 'react-responsive'
import { FETCH_PAYMENT_METHODS } from '../../../graphql/queries/settings-queries.js'
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { useQuery, NetworkStatus } from '@apollo/client'
import EditPaymentMethod from './PaymentMethod/EditPaymentMethod'
import RemovePaymentMethod from './PaymentMethod/RemovePaymentMethod'

export const PaymentMethods = () => {
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false)
  const [openEditPaymentModal, setOpenEditPaymentModal] = useState(false)
  const [openDeletePaymentModal, setOpenDeletePaymentModal] = useState(false)
  const [selectedPaymentmethod, setSelectedPaymentmethod] = useState(null)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const [isAccordianOpen, setIsAccordianOpen] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [isHovered, setIsHovered] = useState(false)
  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState(null)
  const [addPaymentMethodLoading, setAddPaymentMethodLoading] = useState(false)

  const { user } = useAuth0()
  const {
    data,
    loading,
    refetch: refetchPaymentMethods,
    networkStatus,
  } = useQuery(FETCH_PAYMENT_METHODS, {
    variables: {
      loc_id: user.businessId,
      refresh: false,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setPaymentMethods(data.queryPaymentMethodsByLocation.data)
    },
  })
  const isLastPaymentMethod = paymentMethods.length === 1
  const openAddPaymentModalFun = () => {
    setOpenAddPaymentModal(true)
  }

  const handleClose = () => {
    setSelectedPaymentmethod(null)
    setOpenAddPaymentModal(false)
  }

  const toggleAccordian = function () {
    setIsAccordianOpen(!isAccordianOpen)
  }

  const onClickEditPaymentMethod = (billingInfoId) => {
    const selected = paymentMethods.find(
      (payment) => payment.BILLING_INFO_ID === billingInfoId,
    )
    setAddPaymentMethodLoading(false)
    setSelectedPaymentMethodID(billingInfoId)
    setSelectedPaymentmethod(selected)
    setOpenEditPaymentModal(true)
  }

  const onClickDeletePaymentMethod = (billingInfoId) => {
    const selected = paymentMethods.find(
      (payment) => payment.BILLING_INFO_ID === billingInfoId,
    )
    setAddPaymentMethodLoading(false)
    setSelectedPaymentMethodID(billingInfoId)
    setSelectedPaymentmethod(selected)
    setOpenDeletePaymentModal(true)
  }

  const isLoadingContent = () => loading || networkStatus === NetworkStatus.refetch

  const refetchUpdatedPaymentData = () => {
    refetchPaymentMethods({
      loc_id: user.businessId,
      refresh: true,
    })
  }

  return (
    <>
      <div
        className="bg-[white] rounded p-6 border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] sm:w-[95%] sm:m-auto"
        data-testid="payment-methods"
      >
        <div className="pl-[1rem] pr-[1rem] flex items-center justify-between">
          <Header fontWeight="semibold" variant="h6" className="mt-5 mb-5">
            Payment Methods
          </Header>
          {isMobile && (
            <>
              {isAccordianOpen ? (
                <FontAwesomeIcon
                  className="text-[#231F20] ml-[5px]"
                  icon={faCaretUp}
                  size={'lg'}
                  onClick={toggleAccordian}
                  data-testid="caret-up"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size={'lg'}
                  onClick={toggleAccordian}
                  data-testid="caret-down"
                />
              )}
            </>
          )}
        </div>

        {isAccordianOpen && (
          <>
            <div
              className="flex my-[1rem] justify-between items-center sm:w-[92%]  w-[80%] h-[62px] border-[1px] border-[#CCCCCC] border-dashed"
              style={{
                cursor: 'pointer',
              }}
              onClick={openAddPaymentModalFun}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span className="ml-[1rem]">
                <FontAwesomeIcon
                  className="text-[14px] mr-[8px] text-[#CCCCCC] "
                  icon={faCreditCard}
                />
                <span className="text-[12px]">ADD NEW PAYMENT METHOD </span>
              </span>

              <FontAwesomeIcon
                className={`text-[2rem] mr-[1rem] text-[#CCCCCC] ${
                  isHovered && 'text-[#FF5001]'
                }`}
                icon={faCirclePlus}
                data-testid="circle-plus-icon"
              />
            </div>
            {isLoadingContent() && addPaymentMethodLoading && (
              <div className="flex justify-center py-2">
                <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] box-border animate-spin relative top-3"></span>
              </div>
            )}
            {paymentMethods.map((method) => (
              <React.Fragment key={method.BILLING_INFO_ID}>
                {selectedPaymentMethodID === method.BILLING_INFO_ID &&
                isLoadingContent() ? (
                  <div className="flex justify-center py-2">
                    <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] box-border animate-spin relative top-3"></span>
                  </div>
                ) : (
                  <SavedPayment
                    key={method.BILLING_INFO_ID}
                    method={method}
                    onEdit={() => onClickEditPaymentMethod(method.BILLING_INFO_ID)}
                    onDelete={() =>
                      onClickDeletePaymentMethod(method.BILLING_INFO_ID)
                    }
                  />
                )}
              </React.Fragment>
            ))}
          </>
        )}

        <CustomModal
          isModalForm={true}
          heading="Add New Payment Method"
          isOpen={openAddPaymentModal}
          setIsOpen={setOpenAddPaymentModal}
          handleClose={handleClose}
          width={isMobile ? '94%' : '630px'}
        >
          <AddPaymentMethod
            paymentMethods={paymentMethods}
            handleClose={handleClose}
            onComplete={() => {
              setAddPaymentMethodLoading(true)
              setOpenAddPaymentModal(false)
              refetchUpdatedPaymentData()
            }}
          />
        </CustomModal>
        <CustomModal
          isModalForm={true}
          heading="Add New Payment Method"
          isOpen={openEditPaymentModal}
          setIsOpen={setOpenEditPaymentModal}
          width={isMobile ? '94%' : '630px'}
        >
          <EditPaymentMethod
            handleClose={() => setOpenEditPaymentModal(false)}
            paymentMethod={selectedPaymentmethod}
            onComplete={() => {
              setOpenEditPaymentModal(false)
              refetchUpdatedPaymentData()
            }}
          />
        </CustomModal>
        <CustomModal
          isModalForm={true}
          heading="Add New Payment Method"
          isOpen={openDeletePaymentModal}
          setIsOpen={setOpenDeletePaymentModal}
          width={isMobile ? '94%' : '630px'}
        >
          <RemovePaymentMethod
            handleClose={() => setOpenDeletePaymentModal(false)}
            paymentMethod={selectedPaymentmethod}
            onComplete={() => {
              setOpenDeletePaymentModal(false)
              refetchUpdatedPaymentData()
            }}
            isLastPaymentMethod={isLastPaymentMethod}
          />
        </CustomModal>
      </div>
    </>
  )
}
