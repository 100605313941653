import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateInboxNotif } from '../../slices/inbox-notifications/inbox-notifications-slice'
import { setUpdatedContactMergeNotification } from '../../slices/inboxSlice'
import { useSubscription } from '@apollo/client'
import {
  SUBSCRIPTION_FOR_SUCCESSFUL_CONTACT_MERGE,
  FETCH_MESSAGES_BY_THREAD_ID,
  FETCH_THREADS,
} from '../../../../graphql'
import { GET_MESSAGES_BY_PHONE_CHANNEL } from '../../../../graphql'
import { setIncomingContactName, setThreadData } from '../../slices/inboxSlice'
import { useLazyQuery } from '@apollo/client'
import { setMostRecentThread } from '../../slices/inboxThreadsSlice'
import {
  setInboxNotifications,
  setCallsNotifications,
} from '../../../../redux-toolkit/slices/notifications/notifications-slice'
import { setUnsendButtonClicked } from '../../slices/messageComposeSlice'

// Components
import { Notification } from '../../../common/notification'
import { useInboxMenuConversations } from '../../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { AnimatedNotificationContainer } from './animated-notification-container/animated-notification-container'
import { useInboxNotifications } from './use-inbox-notifications'
import { CredentialChangedNotification } from '../../../common/notification'
import {
  setShowReminderNotification,
  setShowCustomNotification,
} from '../../slices/remindMeSlice'
import RegisterPhoneNotifications from '../../../common/modals/phone-certification-modal/register-phone-modal/RegisterPhoneNotifications'
import RegisterBusinessErrorNotif from '../../../common/modals/register-business-name-modal/RegisterBusinessErrorNotif'
import { handleHideDisconnectedChannelsNotification } from '../../../common/modals/slices/connectChannelsSlice'
import usePhoneChannel from '../../../../hooks/use-phone-channel'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useChannelsWithReauthStatus } from '../../../../hooks/use-channels-with-reauth-status'

export const InboxNotifications = ({
  className,
  authUser,
  limitAttachmentErrorIsVisible,
}) => {
  const { selectedChannel, unsendButtonClicked } = useSelector(
    (state) => state.messageCompose,
  )
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const { initMessageThreads, initPinnedMessageThreads, createVariables } =
    useInboxMenuConversations()
  const { channelDisconnected, showChannelDisconnectedNotification } = useSelector(
    (state) => state.connectChannels,
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const [phoneChannel] = usePhoneChannel()
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  const {
    reminderText,
    showReminderNotification,
    showCustomNotification,
    customReminder,
  } = useSelector((state) => state.remindMe)
  const { toastContent, showToast } = useSelector(
    (state) => state.inboxToastNotification,
  )
  const { updatedContactMergeNotification } = useSelector((state) => state.inbox)
  const [, setChannelHistorySuccessfullyImported] = useState(false) // FIXME: This piece of state may be Deprecated

  const dispatch = useDispatch()
  const { inboxNotifications } = useInboxNotifications()

  const { conversationLimit } = useSelector((state) => state.inbox)
  const selectedThreadName = useSelector(
    (state) => state.inboxThreads.selectedThread.name,
  )

  const reauthChannels = useChannelsWithReauthStatus()

  const getIncomingContactName = (data) => {
    if (data.items.length === 0) {
      dispatch(setIncomingContactName(selectedThreadName))
      return
    } else {
      data.items.forEach((message) => {
        if (message?.email_from?.email === selectedThreadName) {
          dispatch(setIncomingContactName(message.email_from.name))
          return
        } else if (message?.dm_from) {
          dispatch(setIncomingContactName(message.dm_from))
          return
        } else if (message?.number_from) {
          dispatch(setIncomingContactName(message.number_from))
          return
        } else {
          dispatch(setIncomingContactName(selectedThreadName))
          return
        }
      })
    }
  }

  const [getMessages, { data: messagesData }] = useLazyQuery(
    FETCH_MESSAGES_BY_THREAD_ID,
    {
      variables: {
        threadid: selectedThreadId,
        first: conversationLimit,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
      onCompleted: () => {
        getIncomingContactName(messagesData.queryMessagesByThread)
        const messages = messagesData.queryMessagesByThread.items.map((message) => {
          return {
            ...message,
            visible: true,
          }
        })
        dispatch(setThreadData(messages?.reverse()))
      },
    },
  )

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
      dispatch(
        setMostRecentThread([
          {
            id: threads?.at(0)?.thread_id,
            name: threads?.at(0)?.thread_name,
          },
          {
            id: threads?.at(1)?.thread_id,
            name: threads?.at(1)?.thread_name,
          },
        ]),
      )
    },
  })

  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)

  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadCalls = recentCallsData.queryMessagesByPhoneChannel.filter(
          (x) => !x.is_read,
        ).length

        dispatch(
          setCallsNotifications({
            calls: unreadCalls || 0,
          }),
        )
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadVoicemails =
          recentVoicemailsData.queryMessagesByPhoneChannel.filter(
            (x) => !x.is_read,
          ).length

        dispatch(
          setCallsNotifications({
            voicemails: unreadVoicemails || 0,
          }),
        )
      },
    },
  )

  const fetchVoicemailsAndCalls = () => {
    getRecentVoicemails({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })
    getRecentCalls({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })
  }

  const [getUnreadThreads, { data: unreadThreadsData }] = useLazyQuery(
    FETCH_THREADS,
    {
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
      variables: {
        folder: 'unread',
      },
      onCompleted: () => {
        const threads = unreadThreadsData?.data?.queryThreads?.items?.filter(
          (x) => x?.messages?.items?.length > 0,
        )
        const pinned = unreadThreadsData?.data?.pinned?.items?.filter(
          (x) => x?.messages?.items?.length > 0 && x?.read === false,
        )
        dispatch(setInboxNotifications(threads?.length + pinned?.length || 0))
      },
    },
  )

  const toggleFileSizeWarningNotif = () => {
    dispatch(
      updateInboxNotif({
        show: limitAttachmentErrorIsVisible,
        name: 'fileSizeWarningNotif',
      }),
    )
  }

  useEffect(() => {
    toggleFileSizeWarningNotif()
  }, [limitAttachmentErrorIsVisible])

  const toggleCredentialChangeNotif = () => {
    if (channelDisconnected?.length > 0 && showChannelDisconnectedNotification) {
      dispatch(
        updateInboxNotif({
          show: true,
          name: 'credentialChangeNotif',
        }),
      )
    }
  }

  useEffect(() => {
    toggleCredentialChangeNotif()
  }, [channelDisconnected, showChannelDisconnectedNotification])

  const toggleReminderNotif = () => {
    dispatch(
      updateInboxNotif({
        show: showReminderNotification,
        name: 'reminderNotif',
      }),
    )
  }

  useEffect(() => {
    toggleReminderNotif()
  }, [showReminderNotification])

  const toggleCustomNotif = () => {
    dispatch(
      updateInboxNotif({
        show: showCustomNotification,
        name: 'customReminderNotif',
      }),
    )
  }

  useEffect(() => {
    toggleCustomNotif()
  }, [showCustomNotification])

  const toggleSuccessfulAustraliaBusinessPhoneNotif = () => {
    dispatch(
      updateInboxNotif({
        show: showToast,
        name: 'customReminderNotif',
      }),
    )
  }

  useEffect(() => {
    toggleSuccessfulAustraliaBusinessPhoneNotif()
  }, [showToast])

  const toggleMergeNotification = () => {
    if (
      updatedContactMergeNotification?.length > 0 &&
      updatedContactMergeNotification === selectedThreadId
    ) {
      dispatch(
        updateInboxNotif({
          show: true,
          name: 'mergeNotification',
        }),
      )
    } else {
      dispatch(
        updateInboxNotif({
          show: false,
          name: 'mergeNotification',
        }),
      )
    }
  }

  useEffect(() => {
    toggleMergeNotification()
  }, [updatedContactMergeNotification, selectedThreadId])

  const { data: mergeAlert } = useSubscription(
    SUBSCRIPTION_FOR_SUCCESSFUL_CONTACT_MERGE,
  )

  const toggleSuccessfulMergeNotification = () => {
    if (mergeAlert && !JSON.parse(mergeAlert?.onGenericUpdate?.body)?.channelId) {
      dispatch(
        updateInboxNotif({
          show: true,
          name: 'successMergeNotification',
        }),
      )
      getMessages()
      getThreads()
      getUnreadThreads()
      fetchVoicemailsAndCalls()
    }
  }

  useEffect(() => {
    toggleSuccessfulMergeNotification()
  }, [mergeAlert])

  const toggleChannelReauthNotification = () => {
    if (reauthChannels?.length === 0) {
      return
    }
    dispatch(
      updateInboxNotif({
        show: true,
        name: 'channelReauthNotification',
      }),
    )
  }

  useEffect(() => {
    toggleChannelReauthNotification()
  }, [reauthChannels])

  const [unsendTimer, setUnsendTimer] = useState(5)

  const notifTimer = (notification) => {
    if (
      notification.show &&
      notification.name === 'unsendMessageNotification' &&
      unsendTimer > 0
    ) {
      const timerId = setTimeout(() => setUnsendTimer(unsendTimer - 1), 1000)
      return () => clearTimeout(timerId)
    }
  }

  const buttonOnclick = () => {
    dispatch(setUnsendButtonClicked(true))
  }

  useEffect(() => {
    let timerId
    const notification = inboxNotifications.find(
      (notif) => notif.name === 'unsendMessageNotification',
    )
    if (notification && notification?.show && unsendTimer > 0) {
      timerId = setTimeout(() => setUnsendTimer(unsendTimer - 1), 1000)
    } else if (unsendTimer === 1 || !notification?.show) {
      setUnsendTimer(5)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [inboxNotifications, unsendTimer])

  useEffect(() => {
    if (!unsendButtonClicked) {
      setUnsendTimer(5)
    }
  }, [unsendButtonClicked])

  return (
    <div className={`${className} w-full`}>
      {/* --------------------------------------- */}
      {/* Notification components live in here ⬇ */}
      {/* --------------------------------------- */}
      <div className={`mx-2 flex flex-col gap-2 justify-end`}>
        {inboxNotifications.map((notification, i) => {
          if (notification.name === 'contactCreateNotifError') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  variant="error"
                  iconType="solid"
                  iconVariant="circleExclamation"
                  className="relative bg-yellow-500 p-2 rounded-lg"
                  textToRender={notification.customText}
                  close={() => {
                    dispatch(
                      updateInboxNotif({
                        show: false,
                        name: 'contactCreateNotifError',
                      }),
                    )
                  }}
                />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'fileSizeWarningNotif') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  variant="warning"
                  iconType="solid"
                  iconVariant="circleExclamation"
                  className="relative bg-yellow-500 p-2 rounded-lg"
                  textToRender={
                    'Your attachment(s) file size has exceeded the limit allowed by the message provider.'
                  }
                />
              </AnimatedNotificationContainer>
            )
          }

          // DEPRECATED ?
          if (notification.name === 'successfulImportNotif') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  className="relative bg-red-500 p-2 rounded-lg"
                  iconType={'solid'}
                  variant={'success'}
                  textToRender={`Congrats, ${authUser.name}! Your [channel] history was successfully imported.`}
                  close={() => setChannelHistorySuccessfullyImported(false)}
                />
              </AnimatedNotificationContainer>
            )
          }

          if (
            notification.name === 'credentialChangeNotif' &&
            channelDisconnected.length > 1
          ) {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <CredentialChangedNotification
                  variant="error"
                  iconType="solid"
                  iconVariant="circleExclamation"
                  className="relative bg-red-500 p-2 rounded-lg"
                  updateButton={true}
                  channelName={selectedChannel?.AccountUserValue}
                  close={() =>
                    dispatch(
                      updateInboxNotif({
                        show: false,
                        name: 'credentialChangeNotif',
                      }),
                    )
                  }
                  handleHideDisconnectedChannelsNotification={() =>
                    dispatch(handleHideDisconnectedChannelsNotification(false))
                  }
                />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'reminderNotif') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  showReminderNotification={showReminderNotification}
                  className="relative bg-blue-500 p-2 rounded-lg"
                  iconType={'solid'}
                  variant={'reminder'}
                  textToRender={`Your reminder has been set. This message will be marked as unread ${reminderText}`}
                  close={() => {
                    dispatch(setShowReminderNotification(false))
                  }}
                />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'customReminderNotif') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  showCustomNotification={showCustomNotification}
                  className="relative bg-blue-500 p-2 rounded-lg"
                  iconType={'solid'}
                  variant={'reminder'}
                  textToRender={`Your reminder has been set. This message will be marked as unread on ${customReminder.customDate} at ${customReminder.customTime}`}
                  close={() => {
                    dispatch(setShowCustomNotification(false))
                  }}
                />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'importedRegisterPhoneNotif' && isActive) {
            return <RegisterPhoneNotifications key={i} />
          }

          if (notification.name === 'importedRegisterBusinessErrorNotif') {
            {
              /* FIXME: May need to revisit this Notification */
            }
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <RegisterBusinessErrorNotif />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'successfulAustraliaBusinessPhoneNotif') {
            {
              /* FIXME: May need to revisit this Notification */
            }
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification {...toastContent} />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'mergeNotification') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  key={i}
                  className="relative bg-red-500 p-2 rounded-lg"
                  iconType={'solid'}
                  variant={'success'}
                  textToRender={`Contacts are merged. Messages are being transferred. You will be notified when transfer is complete.`}
                  close={() => dispatch(setUpdatedContactMergeNotification(false))}
                />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'successMergeNotification') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  key={i}
                  className="relative bg-red-500 p-2 rounded-lg"
                  iconType={'solid'}
                  variant={'success'}
                  textToRender={`Contact has been successfully merged.`}
                  close={() =>
                    dispatch(
                      updateInboxNotif({
                        show: false,
                        name: 'successMergeNotification',
                      }),
                    )
                  }
                />
              </AnimatedNotificationContainer>
            )
          }

          if (
            notification.name === 'unsendMessageNotification' &&
            !unsendButtonClicked
          ) {
            notifTimer(notification)
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  key={i}
                  className="relative bg-green-500 p-2 rounded-lg h-[48px] items-center"
                  iconType={'solid'}
                  variant={'success'}
                  button={unsendTimer > 0}
                  buttonText={'Unsend'}
                  buttonOnclick={buttonOnclick}
                  textToRender={`Sending message${
                    unsendTimer > 0
                      ? `, you can cancel this within ${unsendTimer} seconds.`
                      : ''
                  }`}
                  close={() => {
                    dispatch(
                      updateInboxNotif({
                        show: false,
                        name: 'unsendMessageNotification',
                      }),
                    )
                    setUnsendTimer(5)
                  }}
                />
              </AnimatedNotificationContainer>
            )
          }

          if (notification.name === 'channelReauthNotification') {
            return (
              <AnimatedNotificationContainer show={notification.show} key={i}>
                <Notification
                  key={i}
                  variant="error"
                  iconType="solid"
                  iconVariant="circleExclamation"
                  className="text-left gap-x-3 -mb-3 -mt-5"
                  textToRender="To apply the recent performance update for channels, please reauthenticate your connected channel accounts. Until you complete reauthentification, you will not be able to send or receive messages on those channels."
                />
              </AnimatedNotificationContainer>
            )
          }
        })}
      </div>
    </div>
  )
}
